import { clsx, type ClassValue } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'
import config from '../../tailwind.config'

const customTwMerge = extendTailwindMerge({
  prefix: 'u-',
  extend: {
    classGroups: {
      'font-size': config?.theme?.fontSize
        ? Object.keys(config.theme.fontSize).map((key) => `text-${key}`)
        : [],
    },
  },
})

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(...inputs))
}
