import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils";

// this clases are always applied by default
const defaultClasses = cn(
    "u-inline-flex u-items-center u-justify-center u-gap-2 u-border u-border-solid u-whitespace-nowrap u-rounded-full",
    "u-text-white u-font-900 u-transition-colors",
    "focus:u-outline-dashed focus:u-outline-1 focus:u-outline-offset-2",
    "data-[state=focus]:u-outline-dashed data-[state=focus]:u-outline-1 data-[state=focus]:u-outline-offset-2",
    "[&_svg]:u-pointer-events-none [&_svg]:u-size-2.5 [&_svg]:u-shrink-0"
);

const buttonVariants = cva(
    defaultClasses,
    {
        variants: {
            variant: {
                primary: cn(
                    "u-bg-blue-500 u-border-blue-500",
                    "hover:u-bg-blue-600 hover:u-border-blue-600",
                    "data-[state=hover]:u-bg-blue-600 data-[state=hover]:u-border-blue-600",
                    "focus:u-border-blue-600 focus:u-outline-blue-600",
                    "data-[state=focus]:u-border-blue-600 data-[state=focus]:u-outline-blue-600"
                ),
                secondary: "u-bg-white u-text-blue-500 u-shadow-e2 u-border u-border-black/4",
            },
            ghost: {
                true: "",
            },
            size: {
                small: "u-py-1 u-px-5 u-text-sm",
                medium: "u-py-[0.688rem] u-px-6 u-text-md",
                large: "u-py-[0.688rem] u-px-6 u-text-[1.125rem]/6",
            },
            icon: {
                true: "u-shrink-0 u-p-2.5 u-w-9 u-h-9",
            },
            disabled: {
                true: "u-pointer-events-none",
            },
        },
        compoundVariants: [
            {
                variant: "primary",
                ghost: false,
                disabled: false,
                class: cn(
                    "focus:u-bg-blue-600 focus:u-border-blue-600",
                    "data-[state=focus]:u-bg-blue-600 data-[state=focus]:u-border-blue-600",
                ),
            },
            {
                variant: "primary",
                ghost: false,
                disabled: true,
                class: "u-bg-blue-200 u-border-blue-200 focus:u-bg-blue-600 data-[state=focus]:u-bg-blue-600",
            },
            {
                variant: "primary",
                ghost: true,
                disabled: false,
                class: cn(
                    "u-bg-transparent u-text-blue-500 u-text-blue-500 u-border-blue-500",
                    "hover:u-bg-blue-100 hover:u-text-blue-600 hover:u-border-blue-600",
                    "data-[state=hover]:u-bg-blue-100 data-[state=hover]:u-text-blue-600 data-[state=hover]:u-border-blue-600",
                    "focus:u-text-blue-600 focus:u-border-blue-600",
                    "data-[state=focus]:u-text-blue-600 data-[state=focus]:u-border-blue-600"
                ),
            },
            {
                variant: "primary",
                ghost: true,
                disabled: true,
                class: "u-bg-transparent u-text-blue-300 u-border-blue-300",
            },
            // secondary
            {
                variant: "secondary",
                ghost: false,
                disabled: false,
                class: cn(
                    "hover:u-bg-cyan-100 hover:u-text-blue-600",
                    "data-[state=hover]:u-bg-cyan-100 data-[state=hover]:u-text-blue-600",
                    "focus:u-outline-blue-500",
                    "data-[state=focus]:u-outline-blue-500"
                ),
            },
            {
                variant: "secondary",
                ghost: true,
                disabled: false,
                class: cn(
                    "u-shadow-none u-bg-transparent u-border-white u-text-white",
                    "hover:u-bg-white hover:u-text-blue-600",
                    "data-[state=hover]:u-bg-white data-[state=hover]:u-text-blue-600",
                    "focus:u-outline-white",
                    "data-[state=focus]:u-outline-white",
                ),
            },
            {
                variant: "secondary",
                ghost: false,
                disabled: true,
                class: "u-bg-gray-300 u-shadow-none u-border-gray-300 u-text-white",
            },
            {
                variant: "secondary",
                ghost: true,
                disabled: true,
                class: "u-bg-transparent u-shadow-none u-border-gray-300 u-text-gray-300",
            }
        ],
        defaultVariants: {
            variant: "primary",
            size: "medium",
            ghost: false,
            icon: false,
            disabled: false,
        },
    }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
        disabled?: boolean
}

const Button = ({ className, variant, size, ghost, icon, disabled, ...props }: ButtonProps) => {

    return (
        <button
            className={cn(buttonVariants({ variant, size, ghost, icon, disabled, className }))}
            disabled={disabled}
            data-cy="button"
            {...props}
        />
    )
}

export { Button, buttonVariants }
