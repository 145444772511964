"use client"

import { useState } from "react"

import { cn } from "@/lib/utils"
import { TabsContextProvider } from "./TabsContextProvider"

export type TabsProps = React.HTMLAttributes<HTMLDivElement> & {
    defaultValue: string
    onValueChange?(value: string): void
};

const Tabs = ({ children, defaultValue, onValueChange, className, ...props }: TabsProps) => {
    const [selectedItem, setSelectedItem] = useState<string>(defaultValue)

    const handleOnItemSelect = (itemValue: string) => {
        setSelectedItem(itemValue)

        if (onValueChange) {
            onValueChange(itemValue)
        }
    }

    return (
        <TabsContextProvider
            value={selectedItem}
            onItemSelect={handleOnItemSelect}
        >
            <div
                data-cy="tabs"
                {...props}
                className={
                    cn(
                        className
                    )
                }
            >
                {children}
            </div>
        </TabsContextProvider>
    )
}

export default Tabs
