"use client"

import { createContext, useContext } from "react"

type WizardContextValue =  {
    currentStep: number
    onStepChange?(stepValue: number): void
    nextStep?(): void
    prevStep?(): void
}

export const WizardContext = createContext<WizardContextValue | undefined>(undefined)

type WizardContextProviderProps = WizardContextValue & {
    children: React.ReactNode
}

export const WizardContextProvider = ({ currentStep, onStepChange, children }: WizardContextProviderProps) => {
    return (
        <WizardContext.Provider
            value={{
                currentStep,
                onStepChange
            }} 
        >
            {children}
        </WizardContext.Provider>
    )
}

export const useWizardContext = () => {
    const context = useContext(WizardContext)

    if (!context) {
        throw new Error("useWizardContext must be used within a WizardContext Provider")
    }

    return context
}
