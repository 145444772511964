"use client"
import { useRef, useState } from "react"

import { cn } from "@/lib/utils"
import { useClickOutside } from "@/hooks/useOnClickOutside"
import { Button } from "../Button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../Tabs"
import { Wizard, Step } from "../Wizard"
import ExclamationIcon from '../../../../public/images/icons/exclamation.svg'
import { beam } from "@/helpers/beam"

const validateZip = (zip: string) => {
  const zipRegex = new RegExp(/^\d{5}$/)
  return zipRegex.test(zip)
}

type ZipEntryProps = {
  /** callback to be called when the user clicks Find Plans button */
  onFindPlansClick?(URL: string): void
  variant?: "default" | "geo"
}

const ZipEntry = ({ onFindPlansClick, variant }: ZipEntryProps) => {
  const [zipCode, setZipCode] = useState("")
  const [wasZipCodeFocused, setWasZipCodeFocused] = useState(false)
  const zipCodeRef = useRef(null)
  const [showZipCodeError, setShowZipCodeError] = useState(false)

  const [showMovingError, setShowMovingError] = useState(false)
  const [showApartmentError, setShowApartmentError] = useState(false)
  const [wasRadioClicked, setWasRadioClicked] = useState(false)
  useClickOutside(zipCodeRef, () => setShowZipCodeError(false))

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "")
    setShowZipCodeError(false)
    setZipCode(value)
  }

  const handleZipCodeFocus = () => {
    if (wasZipCodeFocused) {
      return
    }

    setWasZipCodeFocused(true)

    const payload = {
      formContext: {
        formName: "Zip Search Form",
        formType: "Zip Check",
      },
    }
    beam('FormStarted', payload)
    // fire logRocket event
  }

  const handleFirstStepSubmit = (e: React.FormEvent<HTMLFormElement>, callback: () => void) => {
    e.preventDefault()
    const value = new FormData(e.currentTarget).get("zipCode") as string

    if (!validateZip(value)) {
      setShowZipCodeError(true)
      return
    }

    if (validateZip(value)) {
      setShowZipCodeError(false)

      const payload = {
        webElement: {
          location: value,
          position: 'zipEntry',
          elementType: 'cta Button',
          text: 'CONTINUE (Not Existing Customer)'
        },
        outboundUrl: 'shop.txu.com'
      }
      beam('ElementClicked', payload)
      // fire logRocket event

      if (callback && typeof callback === "function") {
        callback()
      }
    }
  }

  const handleRadioClick = (location: string, text: string, actionOutcome: string) => {
    if (wasRadioClicked) {
      return
    }

    setWasRadioClicked(true)

    const payload = {
      webElement: {
        location,
        elementType: 'CTA button',
        text
      },
      actionOutcome
    }
    beam('ElementClicked', payload)
    // fire logRocket event
  }

  const handleSecondStepSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    let URL = `plans/?zipCode=${zipCode}&`

    if (!formData.has("m")) {
      setShowMovingError(true)
      return
    }

    setShowMovingError(false)
    if (!formData.has("a")) {
      setShowApartmentError(true)
      return
    }
    setShowApartmentError(false)

    const queryString = new URLSearchParams(Array.from(formData.entries()).map(([key, value]) => [key, value.toString()])).toString()
    URL += queryString

    const payload = {
      formContext: {
        formType: 'Zip Box',
        formName: 'ZIP/Address Search Form',
        },
      outboundUrl: URL,
      field: [{
        fieldType: 'cta Button',
        fieldName: 'SHOP'
      }]
    }
    beam('FormSubmitted', payload)
    // fire logRocket event

    if (onFindPlansClick && typeof onFindPlansClick === "function") {
      setTimeout(() => {
        onFindPlansClick(URL)
      }, 500)
    }
  }

  const handleOnSignInClick = () => {
    const payload = {
      webElement: {
        location: 'Tabbed Zip Hero',
        position: 'zipEntry',
        elementType: 'cta Button',
        text: 'CONTINUE (Existing Customer)'
      }
    }
    beam('ElementClicked', payload)
    // fire logRocket event

    setTimeout(() => {
      window.location.href = 'https://www.txu.com/signin'
    }, 500)
  }

  return (
    <Tabs defaultValue={"1"}>
      <TabsList>
        <TabsTrigger value="1">New to TXU Energy</TabsTrigger>
        <TabsTrigger value="2">Current customer</TabsTrigger>
      </TabsList>
      <TabsContent value="1" data-cy="zip-entry">
        <Wizard>
          {({ nextStep }) => (
            <>
              <Step
                className="u-flex u-flex-col u-items-center u-gap-4"
              >
                <form onSubmit={(e) => handleFirstStepSubmit(e, nextStep)} className="u-flex u-flex-col u-w-full u-gap-4" noValidate>
                  <h3 className="u-font-600 u-text-base lg:u-text-4.5 u-text-center">Enter your ZIP Code to see plans</h3>
                  <div className={
                    cn(
                      "u-relative u-w-full u-flex u-flex-col u-gap-3",
                      "lg:u-flex-row lg:u-gap-4",
                      variant !== "geo" && "lg:u-bg-white lg:u-rounded-full lg:u-p-1",
                      variant === "geo" && "md:u-bg-transparent md:u-rounded-none md:u-p-0",
                      variant === "geo" && "md:u-flex-col lg:u-flex-row",
                    )
                  }>
                    <div className="u-relative u-w-full">
                      <input
                        ref={zipCodeRef}
                        value={zipCode}
                        onChange={handleZipCodeChange}
                        onFocus={handleZipCodeFocus}
                        id="zipCode"
                        name="zipCode"
                        type="tel"
                        pattern="[0-9]{5}"
                        required
                        maxLength={5}
                        placeholder="Enter ZIP code"
                        autoComplete="off"
                        className={
                          cn(
                            "u-py-3 u-px-4 u-pl-10 u-rounded-full u-w-full u-text-[1.125rem]/5 u-placeholder-gray-600 u-text-gray-700 u-bg-no-repeat u-bg-[position:1rem_center] u-bg-map-marker md:u-outline-none u-leading-[1.625rem] lg:u-leading-6 u-pb-3.5 u-pt-4",
                            variant === "geo" && "u-rounded-none md:u-py-4 lg:u-py-[0.75rem]",
                          )
                        }
                        data-cy="zip-code-input"
                      />
                      <div
                        data-cy="zip-code-error"
                        className={
                          cn(
                            "u-bg-error-accent u-text-white u-rounded-xs u-p-2 u-text-[0.75rem] u-hidden",
                            "u-absolute u-top-full u-items-center u-gap-2",
                            "before:u-content-[''] before:u-block before:u-absolute before:u-top-0 before:u-left-1/4 before:u-border-8 before:u-border-solid before:u-border-transparent before:u-border-t-0 before:u-border-b-error-accent before:-u-translate-y-full before:-u-translate-x-2/4",
                            showZipCodeError && "u-flex"
                          )
                        }
                      >
                        <ExclamationIcon /> Please enter a valid ZIP Code
                      </div>
                    </div>
                    <Button
                      variant="primary"
                      className={
                        cn(
                          "hover:u-bg-blue-500 focus:u-bg-blue-500 u-leading-6 u-py-4 lg:u-px-7 lg:u-text-[1.125rem] u-border-none",
                          variant === "geo" && "md:u-py-4 lg:u-py-[0.688rem]",
                        )
                      }
                      type="submit"
                      data-cy="zip-code-button"
                    >
                      Continue
                    </Button>
                  </div>
                </form>
              </Step>
              <Step>
                <form
                  onSubmit={(e) => handleSecondStepSubmit(e)}
                  className="u-flex u-flex-col u-gap-3 u-w-full"
                  noValidate
                >
                  <fieldset className="u-flex u-flex-col u-items-center u-gap-2 lg:u-block">
                    <legend className="u-mb-2 u-text-center lg:u-float-left">Are you moving to a new address?</legend>
                    <div className="u-group u-flex u-gap-3 u-float-right u-relative">
                      <label
                        data-cy="moving-radio-mYesLabel"
                        id="mYesLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-bg-white u-py-2 u-px-3 u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="movey"
                          name="m"
                          required
                          aria-labelledby="mYesLabel"
                          onClick={() => handleRadioClick('Move/Switch', 'Yes', 'User indicates Yes they are moving')}
                          className={
                            cn(
                              "u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full",
                              "checked:before:u-content-[''] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500"
                            )
                          }
                        />
                        Yes
                      </label>

                      <label
                        data-cy="moving-radio-mNoLabel"
                        id="mNoLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-bg-white u-py-2 u-px-3 u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="moven"
                          name="m"
                          required
                          aria-labelledby="mNoLabel"
                          onClick={() => handleRadioClick('Move/Switch', 'No', 'User indicates No they are not moving')}
                          className={
                            cn(
                              "u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full",
                              "checked:before:u-content-[''] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500"
                            )
                          }
                        />
                        No
                      </label>
                      <div
                        data-cy="moving-error"
                        className={
                          cn(
                            "u-bg-error-accent u-text-white u-rounded-xs u-p-2 u-text-[0.75rem] u-hidden u-w-max",
                            "u-absolute u-z-10 u-top-full u-items-center u-gap-2",
                            "before:u-content-[''] before:u-block before:u-absolute before:u-top-0 before:u-left-1/4 before:u-border-8 before:u-border-solid before:u-border-transparent before:u-border-t-0 before:u-border-b-error-accent before:-u-translate-y-full before:-u-translate-x-2/4",
                            showMovingError && "u-flex"
                          )
                        }
                      >
                        <ExclamationIcon /> Please select one of these options.
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="u-flex u-flex-col u-items-center u-gap-2 lg:u-block">
                    <legend className="u-mb-2 u-text-center lg:u-float-left">Are you moving to an apartment?</legend>
                    <div className="u-group u-flex u-gap-3 u-float-right u-relative">
                      <label
                        data-cy="apartment-radio-aYesLabel"
                        id="aYesLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-bg-white u-py-2 u-px-3 u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="apartmenty"
                          name="a"
                          required
                          aria-labelledby="aYesLabel"
                          className={
                            cn(
                              "u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full",
                              "checked:before:u-content-[''] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500"
                            )
                          }
                        />
                        Yes
                      </label>

                      <label
                        data-cy="apartment-radio-aNoLabel"
                        id="aNoLabel"
                        className="u-flex u-items-center u-justify-center u-gap-3 u-bg-white u-py-2 u-px-3 u-rounded-xs u-text-gray-700"
                      >
                        <input
                          type="radio"
                          value="apartmentn"
                          name="a"
                          required
                          aria-labelledby="aNoLabel"
                          className={
                            cn(
                              "u-grid u-appearance-none u-w-5 u-aspect-square u-place-content-center u-border-2 u-border-gray-500 u-rounded-full",
                              "checked:before:u-content-[''] checked:before:u-w-3 checked:before:u-aspect-square checked:before:u-rounded-full checked:before:u-bg-blue-500"
                            )
                          }
                        />
                        No
                      </label>
                      <div
                        data-cy="apartment-error"
                        className={
                          cn(
                            "u-bg-error-accent u-text-white u-rounded-xs u-p-2 u-text-[0.75rem] u-hidden u-w-max",
                            "u-absolute u-z-10 u-top-full u-items-center u-gap-2",
                            "before:u-content-[''] before:u-block before:u-absolute before:u-top-0 before:u-left-1/4 before:u-border-8 before:u-border-solid before:u-border-transparent before:u-border-t-0 before:u-border-b-error-accent before:-u-translate-y-full before:-u-translate-x-2/4",
                            showApartmentError && "u-flex"
                          )
                        }
                      >
                        <ExclamationIcon /> Please select one of these options.
                      </div>
                    </div>
                  </fieldset>
                  <Button
                    variant="primary"
                    type="submit"
                    data-cy="find-plans-button"
                    className="u-mt-3 hover:u-bg-blue-500 focus:u-bg-blue-500"
                  >
                    Find Plans
                  </Button>
                </form>
              </Step>
            </>
          )}
        </Wizard>
      </TabsContent>
      <TabsContent value="2">
        <div className="u-flex u-flex-col u-items-center u-gap-4">
          <h3 className="u-font-600 u-text-base lg:u-text-4.5">Sign in to see plans</h3>
          <Button
            variant="primary"
            size="large"
            onClick={handleOnSignInClick}
            className="u-w-full md:u-w-auto md:u-px-14"
          >
            Sign In
          </Button>
        </div>
      </TabsContent>
    </Tabs>
  )
}

export default ZipEntry
