"use client"

import { cn } from "@/lib/utils"
import { useTabsContext } from "./TabsContextProvider"

type TabsTriggerProps = React.HTMLAttributes<HTMLButtonElement> & {
    value: string
}

const TabsTrigger = ({ children, className, value, ...props }: TabsTriggerProps) => {
    const context = useTabsContext()
    const isActive = context.value === value

    return (
        <button
            data-cy={`tabs-trigger tabs-trigger-${value} ${isActive ? 'active' : ''}`}
            {...props}
            role="tab"
            aria-selected={isActive}
            className={
                cn(
                    "u-grow u-text-sm u-font-400 u-text-gray-700 !u-leading-[0.875rem] u-m-0 u-cursor-pointer u-bg-[#EEEEF1] u-px-[1.125rem] u-pt-[0.6875rem] u-pb-[0.9375rem] u-rounded-t-[0.625rem]",
                    "lg:u-grow-0 lg:u-text-base lg:!u-leading-[0.875rem] lg:u-px-[1.125rem]",
                    isActive && "u-text-white u-font-700 u-bg-blue-600 u-py-[0.9375rem]",
                    className
                )
            }
            onClick={() => context.onItemSelect(value)}
        >
            {children}
        </button>
    )
}

export default TabsTrigger
