"use client"

import React, { ReactElement, ReactNode, useState } from 'react'

import { cn } from "@/lib/utils"
import { WizardContextProvider } from "./WizardContextProvider"

type ChildrenRenderProp = {
    nextStep: () => void
    prevStep: () => void
}

type WizardProps = {
    className?: string
    /** index-based number to point which step should be visible
     * @default 0
     */
    currentStep?: number
    /** callback to be called when the step changes */
    onStepChange?(stepValue: number): void
    /** function that returns a ReactNode
     * @param props - object with nextStep and prevStep functions
     * @returns ReactNode
     * e.g. (props) => <Step><button onClick={props.nextStep}>Next</button></Step>
     */
    children: (props: ChildrenRenderProp) => React.ReactNode
}

const Wizard = ({ currentStep = 0, onStepChange, children, className, ...props }: WizardProps) => {
    const [selectedStep, setSelectedStep] = useState<number>(currentStep)
    const nextStep = () => setSelectedStep((prev) => prev + 1)
    const prevStep = () => setSelectedStep((prev) => prev - 1)
    const kids = children({nextStep, prevStep}) as ReactElement<{ children: ReactNode }>
    const steps = React.Children.toArray(React.isValidElement(kids) ? kids.props.children : null).map((child: any) => child)
    const isCurrentStep = (index: number) => index === selectedStep
    const isCurrentStepValid = React.isValidElement(kids) && React.Children.count(kids.props.children) > 0 && selectedStep < React.Children.count(kids.props.children)

    const handleOnStepChange = (stepValue: number) => {
        setSelectedStep(stepValue)

        if (onStepChange) {
            onStepChange(stepValue)
        }
    }

    if (!isCurrentStepValid) {
        throw new Error("Wizard component must have at least one Step component as a child")
    }

    return (
        <WizardContextProvider
            currentStep={selectedStep}
            onStepChange={handleOnStepChange}
        >
            <div
                data-cy="wizard"
                {...props}
            >
                {steps?.map((step: ReactNode, index: number) => (
                    <div
                        key={index}
                        className={
                            cn(
                                "u-hidden",
                                isCurrentStep(index) && "u-block",
                                className
                            )
                        }
                    >
                        {step}
                    </div>
                ))}

            </div>
        </WizardContextProvider>
    )

}

export default Wizard
