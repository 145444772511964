/** Tagular Beam Functions
 * Used to send tagular events
 */

export interface TagularData {
  webElement?: TagularWebElementData
  field?: TagularFieldData[]
  userInputField?: TagularFieldData
  formContext?: TagularFormData
  actionOutcome?: string
  outboundUrl?: string
  product?: TagularProduct | TagularProduct[]
}

interface TagularWebElementData {
  elementType?: string
  location?: string
  name?: string
  position?: string
  text?: string
}
interface TagularFieldData {
  fieldName?: string
  fieldType?: string
  fieldValue?: string | null
  fieldId?: string
}

interface TagularFormData {
  formId?: string
  formName?: string
  formType?: string
  formVersion?: string
  formBrand?: string
}

interface TagularProduct {
  location?: string
  name?: string
  price?: number
  productId?: string
  text?: string
  quantity?: number
  brand?: string
  category?: string
  formatType?: string
  position?: number
}

export interface TagularFunction {
  (action: string, eventName: string, data: any): void
}

declare const window: {
  tagular: TagularFunction
}

export function beam(eventName: string, data: TagularData) {
  if (window.tagular) {
    window.tagular('beam', eventName, {
      '@type': `redventures.usertracking.v3.${eventName}`,
      ...data,
    })
  }
}

export function beamFull(fullEventName: string, data: TagularData) {
  if (window.tagular) {
    window.tagular('beam', fullEventName, {
      '@type': `${fullEventName}`,
      ...data,
    })
  }
}
