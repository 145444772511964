import { cn } from "@/lib/utils"

type TabsListProps = React.HTMLAttributes<HTMLDivElement>

const TabsList = ({ children, className, ...props}: TabsListProps) => {
    return (
        <div
            data-cy="tabs-list"
            {...props}
            className={
                cn(
                    "u-flex u-justify-between u-items-end u-gap-[0.125rem] u-relative md:u-justify-start",
                    className
                )
            }
        >
            {children}
        </div>
    )
}

export default TabsList
