"use client";

import { createContext, useContext } from "react";

type TabsContextValue =  {
    value: string;
    onItemSelect(itemValue: string): void;
};

export const TabsContext = createContext<TabsContextValue | undefined>(undefined);

type TabsContextProviderProps = TabsContextValue & {
    children: React.ReactNode;
};

export const TabsContextProvider = ({ value, onItemSelect, children }: TabsContextProviderProps) => {
    return (
        <TabsContext.Provider
            value={{
                value,
                onItemSelect
            }}
        >
            {children}
        </TabsContext.Provider>
    );
};

export const useTabsContext = () => {
    const context = useContext(TabsContext);

    if (!context) {
        throw new Error("useTabsContext must be used within a TabsContext Provider");
    }

    return context;
};
