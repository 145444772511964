"use client"

import { createContext, useContext, useId } from "react"

import { cn } from "@/lib/utils"

export const StepContext = createContext<string | undefined>(undefined)

export const useStepContext = () => {
    const context = useContext(StepContext)

    if (!context) {
        throw new Error("useStepContext must be used within a StepContext Provider")
    }

    return context
}

type StepProps = React.HTMLAttributes<HTMLDivElement>

const Step = ({ children, className, ...props }: StepProps) => {
    const id = useId()

    return (
        <StepContext.Provider value={id}>
            <div
                data-cy="step"
                {...props}
                className={
                    cn(
                        className
                    )
                }
            >
                {children}
            </div>
        </StepContext.Provider>
    )
}

export default Step
