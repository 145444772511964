"use client"

import { cn } from "@/lib/utils"
import { useTabsContext } from "./TabsContextProvider"

type TabsContentProps = React.HTMLAttributes<HTMLDivElement> & {
    value: string
}

const TabsContent = ({ children, value, className, ...props }: TabsContentProps) => {
    const context = useTabsContext()
    const isActive = context.value === value

    return (
        <div
            data-cy={`tabs-content tabs-content-${value} ${isActive ? 'active' : ''}`}
            {...props}
            tabIndex={0}
            role="tabpanel"
            className={
                cn(
                    "u-p-6 u-rounded-b-[0.625rem] u-hidden lg:u-p-10 lg:u-rounded-tr-[0.625rem]",
                    isActive && "u-block u-bg-blue-600 u-text-white",
                    className
                )
            }
        >
            {children}
        </div>
    )
}

export default TabsContent
